<template>
    <div class="m-0 px-3 pt-4 pb-0 pr-2 recommended-reading-index">
        <back-button class="ml-4" />
        <div class="ml-2 d-flex justify-content-between">
            <h1>{{ `Recommended Readings` }}</h1>
            <div>
                <base-button type="dark-blue" size="sm" class="px-4"
                    @click="$router.push({ name: 'admin.recommended-readings.store' })">Add New</base-button>
            </div>
        </div>
        <div class="mt-4 cardStyle p-3">
            <div class="d-flex flex-wrap align-items-center filters" v-if="recommendedReadingsSelected.length == 0">
                <base-input label="Search" type="search" v-model="search.search" @input="filterSearch" placeholder="Search"
                    class="filter-search mr-2"></base-input>
                <base-select label="Status" v-model="search.status" @change="filterSearch" :options="activeOptions"
                    custom-clearable class="filter-search mr-2"></base-select>
            </div>
            <div class="d-flex flex-wrap py-4" v-else>
                <base-button type="danger" outline size="xl" @click="showMultipleDelete" :disabled="loaders.multiple">Delete</base-button>
                <base-button type="active-green" outline size="xl" @click="toggleStatusMultiple(true)" :disabled="loaders.multiple">Activate</base-button>
                <base-button type="spruce-blue" outline size="xl" @click="toggleStatusMultiple(false)" :disabled="loaders.multiple">Deactivate</base-button>
            </div>
            <el-table class="mt-4 mb-0 table-responsive table-flush" header-row-class-name="thead-light" width="100%"
                :data="recommendedReadings" v-loading="loaders.fetching" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="50">
                </el-table-column>
                <el-table-column label="NAME" prop="name" width="550">
                    <template slot-scope="scope">
                        <div class="text-break">
                            {{ scope.row.name }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="Description" prop="description" >
                    <template slot-scope="scope">
                        <div class="trim-text-overflow w-75" v-html="convertStringToLink(scope.row.description)">
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="STATUS" prop="status" width="250" header-align="left" align="left">
                    <template slot-scope="scope">
                            <i class="fa fa-circle mr-2 "
                                :class="{ 'text-active-green': scope.row.status, 'text-silver': !scope.row.status }"
                                style="font-size: 7px;"></i>
                            <p class="text-break m-0 status d-inline-block" style="font-size: inherit;">{{ scope.row.status ? "Active" :
                                "Inactive"
                            }}</p>
                    </template>
                </el-table-column>
                <el-table-column label="ACTIONS" header-align="left" width="200">
                    <div slot-scope="{ $index, row }" class="d-flex">
                        <router-link :to="{ name: 'admin.recommended-readings.show', params: { id: row.id } }">
                            <img class="eye-icon mr-2" src="/img/eye.svg" alt="" />
                        </router-link>
                        <router-link :to="{ name: 'admin.recommended-readings.edit', params: { id: row.id } }">
                            <img src="/img/icons/buttons/edit.svg" class="mr-2" alt="" />
                        </router-link>
                        <img src="/img/icons/buttons/delete.svg" style="cursor: pointer;" alt=""
                            @click="deleteRecommendedReading(row)" />
                    </div>
                </el-table-column>
            </el-table>
            <div class="d-flex justify-content-end mt-4 mr-5">
                <custom-pagination class="pagination-no-border" v-model="pagination.current_page"
                    :per-page="pagination.per_page" :total="pagination.total" @input="changePage" />
            </div>
        </div>
        <div class="modals">
            <remove-modal :loader="loaders.removeModal" :modal-text="remove.modalText" :removeId="remove.id"
                @onRemove="removeRecommendedReading" @onRemoveMultiple="removeRecommendedReadingMultiple" />
        </div>
    </div>
</template>
<script>
import BackButton from "@/components/Router/BackButton";
import { Table, TableColumn } from "element-ui";
import CustomPagination from "@/views/Components/Pagination/CustomPagination";
import RemoveModal from "@/components/Modals/RemoveModal.vue";
import convertStringToAnchorTagMixin from "@/mixins/convertStringToAnchorTagMixin";
import moment from "moment";
export default {
    components: {
        BackButton,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        CustomPagination,
        RemoveModal,
    },
    mixins:[convertStringToAnchorTagMixin],
    data() {
        return {
            loaders: {
                fetching: false,
                removeModal: false,
                multiple: false,
            },
            search: {
                search: '',
                status: '',
            },
            remove: {
                modalText: '',
                id: ''
            },
            pagination: {
                current_page: 1,
                per_page: 0,
                total: 0
            },
            activeOptions: [
                {
                    label: 'All Readings',
                    value: ''
                },
                {
                    label: 'Active',
                    value: 1
                },
                {
                    label: 'Inactive',
                    value: 0
                },
            ],
            recommendedReadings: [],
            recommendedReadingsSelected: []
        };
    },
    mounted() {
        this.getRecommendedReadings();
    },
    methods: {
        showMultipleDelete() {
            this.$store.commit("showRemoveModal");
            this.remove.modalText = `You want to remove these selected Readings.`;
            this.remove.id = this.recommendedReadingsSelected;
        },
        deleteRecommendedReading(item) {
            this.$store.commit("showRemoveModal");
            this.remove.modalText = `You want to remove this Reading "${item.name}".`;
            this.remove.id = item.id;
        },
        async removeRecommendedReading() {
            this.loaders.removeModal = true;
            try {
                let id = this.remove.id;
                let response = await this.$store.dispatch('RecommendedReadingsModule/_deleteRecommendedReadingsById', { id });
                let {
                    data: { data, message },
                } = response;
                this.$notify.success({
                    title: 'Recommended Reading',
                    message: message
                });
                this.$store.commit("hideRemoveModal");
                if(this.recommendedReadings.length == 1){
                    if(this.pagination.current_page > 1){
                        this.pagination.current_page = this.pagination.current_page - 1;
                    }
                }
                this.getRecommendedReadings();

            } catch (error) {
                console.log(error);
                this.$notify.error({
                    title: 'Recommended Reading',
                    message: error?.response?.data?.message || 'Something went wrong! Try again later.'
                });
            }
            this.loaders.removeModal = false;
        },
        async removeRecommendedReadingMultiple() {
            this.loaders.removeModal = true;
            this.loaders.multiple = true;
            try {
                let id = this.remove.id;
                let response = await this.$store.dispatch('RecommendedReadingsModule/_deleteMultipleRecommendedReadings', { ids: id });
                let {
                    data: { data, message },
                } = response;
                this.$notify.success({
                    title: 'Recommended Reading',
                    message: message
                });
                this.$store.commit("hideRemoveModal");
                if (this.recommendedReadings.length == 1 || this.recommendedReadings.length == this.remove.id.length) {
                    if(this.pagination.current_page > 1){
                        this.pagination.current_page = this.pagination.current_page - 1;
                    }
                }
                this.getRecommendedReadings();

            } catch (error) {
                console.log(error);
                this.$notify.error({
                    title: 'Recommended Reading',
                    message: error?.response?.data?.message || 'Something went wrong! Try again later.'
                });

            }
            this.loaders.removeModal = false;
            this.loaders.multiple = false;
        },
        async toggleStatusMultiple(status) {
            this.loaders.multiple = true;
            try {
                let id = this.recommendedReadingsSelected;
                let response = await this.$store.dispatch('RecommendedReadingsModule/_toggleStatusMultipleRecommendedReadings', { fd: { ids: id, status } });
                let {
                    data: { data, message },
                } = response;
                this.$notify.success({
                    title: 'Recommended Reading',
                    message: message
                });
                this.getRecommendedReadings();

            } catch (error) {
                console.log(error);
                this.$notify.error({
                    title: 'Recommended Reading',
                    message: error?.response?.data?.message || 'Something went wrong! Try again later.'
                });
            }
            this.loaders.multiple = false;
        },
        changePage() {
            this.getRecommendedReadings();
        },
        async filterSearch() {
            this.pagination.current_page = 1;
            await this.getRecommendedReadings()
        },
        formatDate(date) {
            let formattedDate = moment(date);
            if (formattedDate.isValid()) return formattedDate.format("D-MMM-YYYY");
            return '';
        },
        async getRecommendedReadings() {
            this.loaders.fetching = true;
            try {
                let response = await this.$store.dispatch('RecommendedReadingsModule/_getAllRecommendedReadings', { params: { page: this.pagination.current_page, ...this.search } });
                let data = response?.data?.data;
                if(data){
                this.pagination.current_page = data.current_page;
                this.pagination.per_page = data.per_page;
                this.pagination.total = data.total;
                this.recommendedReadings = data.data;
                }
            } catch (error) {
                this.pagination.current_page = 1;
                this.pagination.per_page = 0;
                this.pagination.total = 0;
                this.recommendedReadings = [];

            }
            this.loaders.fetching = false;
        },
        handleSelectionChange(val) {
            this.recommendedReadingsSelected = val.map((el) => el.id);
        }

    },
};
</script>
<style lang="scss">
.recommended-reading-index {
    .filters {
        .filter-search {
            max-width: 180px;

            .form-control {
                height: calc(1.5em + 1.25rem + 2px);
            }
        }
    }

    .el-table {
        td {
            background-color: white;
            .status{
                font-size: 12px;
                font-weight: 600;
            }
        }

        .el-checkbox__input {
            &.is-checked {
                .el-checkbox__inner {
                    background-color: #FF6421 !important;
                    border-color: #FF6421 !important;
                }
            }
        }
    }
}</style>
  